const designTabSurveyThemeJSON = {
  "cssVariables": {
    "--sjs-base-unit": "var(--ctr-surface-base-unit)",
    "--sjs-font-size": "calc(2 * var(--ctr-surface-base-unit))",
    "--sjs-primary-backcolor": "var(--sjs-primary-background-500, #19B394)",
    "--sjs-primary-backcolor-light": "var(--sjs-primary-background-10, #19B3941A)",
    "--sjs-primary-backcolor-dark": "var(--sjs-primary-background-400, #14A48B)",
    "--sjs-primary-forecolor": "var(--sjs-primary-foreground-100, #FFFFFF)",
    "--sjs-primary-forecolor-light": "var(--sjs-primary-foreground-25, #FFFFFF40)",
    "--sjs-secondary-backcolor": "var(--sjs-secondary-background-500, #19B394)",
    "--sjs-secondary-backcolor-light": "var(--sjs-secondary-background-10, #19B3941A)",
    "--sjs-secondary-backcolor-semi-light": "var(--sjs-secondary-background-25, #19B39440)",
    "--sjs-secondary-forecolor": "var(--sjs-secondary-foreground-100, #FFFFFF)",
    "--sjs-secondary-forecolor-light": "var(--sjs-secondary-forecolor-25, #FFFFFF40)",
    "--sjs-general-backcolor": "var(--sjs-layer-1-background-500, #FFFFFF)",
    "--sjs-general-backcolor-dark": "var(--sjs-layer-1-background-400, #F5F5F5)",
    "--sjs-general-forecolor": "var(--sjs-layer-1-foreground-100, #000000E6)",
    "--sjs-general-forecolor-light": "var(--sjs-layer-1-foreground-50, #00000080)",
    "--sjs-general-backcolor-dim": "var(--sjs-layer-3-background-500, #F4F4F4)",
    "--sjs-general-dim-forecolor": "var(--sjs-layer-3-foreground-100, #000000E6)",
    "--sjs-general-dim-forecolor-light": "var(--sjs-layer-3-foreground-50, #00000080)",
    "--sjs-general-backcolor-dim-light": "var(--sjs-layer-2-background-500, #F8F8F8)",
    "--sjs-general-backcolor-dim-dark": "var(--sjs-layer-2-background-400, #EEEEEE)",
    "--sjs-border-default": "var(--sjs-border-25, #D4D4D4)",
    "--sjs-border-light": "var(--sjs-border-10, #DCDCDC)",
    "--sjs-border-inside": "var(--sjs-border-25, #D4D4D4)",
    "--sjs-special-red": "var(--sjs-semantic-red-background-500, #E50A3E)",
    "--sjs-special-red-light": "var(--sjs-semantic-red-background-10, #E50A3E1A)",
    "--sjs-special-red-forecolor": "var(--sjs-semantic-red-foreground-100, #FFFFFF)",
    "--sjs-special-green": "var(--sjs-semantic-green-background-500, #19B394)",
    "--sjs-special-green-light": "var(--sjs-semantic-green-background-10, #19B3941A)",
    "--sjs-special-green-forecolor": "var(--sjs-semantic-green-foreground-100, #FFFFFF)",
    "--sjs-special-blue": "var(--sjs-semantic-blue-background-500, #437FD9)",
    "--sjs-special-blue-light": "var(--sjs-semantic-blue-background-10, #437FD91A)",
    "--sjs-special-blue-forecolor": "var(--sjs-semantic-blue-foreground-100, #FFFFFF)",
    "--sjs-special-yellow": "var(--sjs-semantic-yellow-background-500, #FF9814)",
    "--sjs-special-yellow-light": "var(--sjs-semantic-yellow-background-10, #FF98141A)",
    "--sjs-special-yellow-forecolor": "var(--sjs-semantic-yellow-foreground-100, #FFFFFF)",
    "--sjs-special-white": "var(--sjs-semantic-white-background-500, #FFFFFF)",
    "--sjs-general-haze-background": "var(--sjs-special-haze, #CCEEEE59)",
    "--sjs-shadow-large-offset-x": "var(--lbr-shadow-large-offset-x)",
    "--sjs-shadow-large-offset-y": "var(--lbr-shadow-large-offset-y)",
    "--sjs-shadow-large-blur": "var(--lbr-shadow-large-blur)",
    "--sjs-shadow-large-spread": "var(--lbr-shadow-large-spread)",
    "--sjs-shadow-large-color": "var(--lbr-shadow-large-color)",
    "--sjs-shadow-medium-offset-x": "var(--lbr-shadow-medium-offset-x)",
    "--sjs-shadow-medium-offset-y": "var(--lbr-shadow-medium-offset-y)",
    "--sjs-shadow-medium-blur": "var(--lbr-shadow-medium-blur)",
    "--sjs-shadow-medium-spread": "var(--lbr-shadow-medium-spread)",
    "--sjs-shadow-medium-color": "var(--lbr-shadow-medium-color)",
    "--sjs-shadow-small-offset-x": "var(--lbr-shadow-small-offset-x)",
    "--sjs-shadow-small-offset-y": "var(--lbr-shadow-small-offset-y)",
    "--sjs-shadow-small-blur": "var(--lbr-shadow-small-blur)",
    "--sjs-shadow-small-spread": "var(--lbr-shadow-small-spread)",
    "--sjs-shadow-small-color": "var(--lbr-shadow-small-color)",
    "--sjs-shadow-inner-offset-x": "var(--lbr-shadow-inner-offset-x)",
    "--sjs-shadow-inner-offset-y": "var(--lbr-shadow-inner-offset-y)",
    "--sjs-shadow-inner-blur": "var(--lbr-shadow-inner-blur)",
    "--sjs-shadow-inner-spread": "var(--lbr-shadow-inner-spread)",
    "--sjs-shadow-inner-color": "var(--lbr-shadow-inner-color)",
  }
};

export default designTabSurveyThemeJSON;