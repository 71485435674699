export const pgTabIcons = {
  "appearance": "icon-pg-appearance-24x24",
  "background": "icon-pg-background-24x24",
  "columns": "icon-pg-columns-24x24",
  "logic": "icon-pg-conditions-24x24",
  "data": "icon-pg-data-24x24",
  "design": "icon-pg-design-24x24",
  "general": "icon-pg-general-24x24",
  "header": "icon-pg-header-24x24",
  "language": "icon-pg-language-24x24",
  "layout": "icon-pg-design-24x24",
  "logo": "icon-pg-logo-24x24",
  "navigation": "icon-pg-navigation-24x24",
  "pages": "icon-pg-pages-24x24",
  "question": "icon-pg-questiondesign-24x24",
  "questionSettings": "icon-pg-questiondesign-24x24",
  "timer": "icon-pg-quiz-24x24",
  "rows": "icon-pg-rows-24x24",
  "specific": "icon-pg-specific-24x24",
  "showOnCompleted": "icon-pg-thankyyoupage-24x24",
  "themes": "icon-pg-themes-24x24",
  "totals": "icon-pg-totals-24x24",
  "validation": "icon-pg-validation-24x24",
  "cells": "icon-pg-specific-24x24",
  "items": "icon-pg-specific-24x24",
  "mask": "icon-pg-specific-24x24",
  "rateValues": "icon-pg-specific-24x24",
  "choices": "icon-pg-specific-24x24",
  "choicesByUrl": "icon-pg-webservice-24x24",
  "undefined": "icon-pg-undefined-24x24",
};